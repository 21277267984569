import { Component, OnInit } from "@angular/core";
import { CActivatorableComponent } from "../../_activatorable.component";
import { CAppService } from "src/app/common/services/app.service";
import { CPaymentPanelService, TPaymentPanelMode } from "./payment.panel.service";
import { BehaviorSubject, filter } from "rxjs";
import { ITariff } from "src/app/model/entities/tariff";
import { CAuthService } from "src/app/common/services/auth.service";
import { IUser } from "src/app/model/entities/user";
import { Appearance, Stripe, StripeConstructorOptions, StripeElements, loadStripe } from "@stripe/stripe-js";
import { ini } from "src/app/app.ini";
import { domGetElementById } from "src/app/common/functions/dom";
import { TBusyState } from "src/app/model/busy.state";
import { mscPause } from "src/app/common/functions/misc";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "panel-payment",
    templateUrl: "payment.panel.component.html",
    styleUrl: "payment.panel.component.scss",
    standalone: true,   
    imports: [FormsModule],
})
export class CPaymentPanelComponent extends CActivatorableComponent implements OnInit {  
    public step: "review" | "card" = "review";
    private stripe: Stripe = null;
    private stripeElements: StripeElements = null;
    public stripeError: string = null;
    public saving: TBusyState = null;    

    constructor(
        protected override appService: CAppService,
        protected panelService: CPaymentPanelService,
        protected authService: CAuthService,
    ) 
    {
        super(appService);
    }

    get activator(): BehaviorSubject<boolean> {return this.panelService.active;}
    get tariff(): ITariff {return this.panelService.tariff;}
    set confirmed(v: boolean) {this.panelService.confirmed = v;}
    get user(): IUser {return this.authService.user.value;}
    get card_no(): string {return this.user.stripe_card_no;}
    get card_brand(): string {return this.user.stripe_card_brand;}
    get stripeKey(): string {return this.appService.settings["stripe-public-key"];}

    //////////////////
    // lifecycle
    //////////////////

    ngOnInit(): void {
        this.activator.pipe(filter(a => a)).subscribe(() => { // reset on activation            
            this.step = "review";
            this.stripeError = null;
        }); 
    }

    ////////////////
    // events
    ////////////////

    public onConfirm(): void {
        this.confirmed = true;
        this.onClose();
    }

    public async toCard(): Promise<void> {
        try {
            this.step = "card";
            const secret = await this.authService.createStripeIntent();
            this.stripe = await loadStripe(this.stripeKey, {locale: this.lang.slug as StripeConstructorOptions["locale"]});
            this.stripeElements = this.stripe.elements({clientSecret: secret, appearance: ini.stripeAppearance.invert as Appearance});             
            const element = await domGetElementById("ps-stripe-form");
            this.stripeElements.create("payment", {layout: "tabs"}).mount(element);
        } catch (err) {
            this.appService.notifyError(err);
        }
    }

    public async onSaveCard(): Promise<void> {
        try {
            this.saving = "busy";
            const res = await this.stripe.confirmSetup({elements: this.stripeElements, redirect: "if_required"}); // "if_required" - отключаем принудительный редирект

            // stripe подтвердил
            if (!res.error) {
                await this.authService.saveStripeCard(res.setupIntent.payment_method as string);
                this.saving = "done";
                await mscPause(1000);
                this.saving = null;
                this.confirmed = true;
                this.onClose();
                return;
            }

            // stripe не подтвердил
            this.saving = null;
            this.stripeError = res.error.type !== "validation_error" ? res.error.message : null; // validation_error показывает сама форма, их не надо дополнительно показывать            
        } catch (err) {
            this.appService.notifyError(err);
            this.saving = null;
        }
    }
}
