<div class="error-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="error" [class.active]="active">
    <div class="e-head">
        <div class="e-title">@if (mode === "error") {<span>Error</span>}</div>
        <button class="roundbtn rbtn-close" (click)="onClose()"><span></span></button>
    </div>
    <div class="e-msg" [innerHTML]="msg"></div>
    <div class="e-foot">
        @if (mode === "error") {<button class="btn btn-reload" (click)="onReload()"><span>Reload</span></button>}
        @if (mode === "message") {<button class="btn" (click)="onClose()"><span>OK</span></button>}
    </div>
</div>
