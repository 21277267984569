import { Component } from "@angular/core";
import { CComponent } from "../../_component";
import { Router, RouterLink } from "@angular/router";
import { CAppService } from "src/app/common/services/app.service";

@Component({
    selector: "the-header",
    templateUrl: "header.component.html",
    styleUrl: "header.component.scss",
    standalone: true,
    imports: [RouterLink],
})
export class CHeaderComponent extends CComponent {
    constructor(
        protected override appService: CAppService,
        protected router: Router,
    ) 
    {
        super(appService);
    }

    get url(): string[] {return this.appService.url;}
    get isWebview(): boolean {return window.navigator.userAgent === "robocat-webview";}
    get backRoute(): string {
        if (this.url[2]) {
            if (this.url[2] === "control") return this.url[3] ? `/${this.lang.slug}` : null;            
            return this.url[3] ? `/${this.lang.slug}/${this.url[2]}` : `/${this.lang.slug}`;            
        }
        return null;
    }

    public onMenu(): void {
        this.appService.menuActive.next(true);
    }

    public onReload(): void {
        document.location = document.location;
    }

    public onLogoClick(): void {
        if (this.url[2]) {
            this.router.navigateByUrl(`/${this.lang.slug}`);
        } else {
            document.location = document.location;
        }
    }
}
