import { ApplicationConfig, ErrorHandler, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { headersInterceptor } from './common/interceptors/headers.interceptor';
import { authInterceptor } from './common/interceptors/auth.interceptor';
import { CErrorsService } from './common/services/errors.service';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}), 
        provideRouter(routes),
        provideHttpClient(
            withInterceptors([            
                headersInterceptor,
                authInterceptor,
            ]),
        ),
        {provide: ErrorHandler, useClass: CErrorsService},
    ]
};
