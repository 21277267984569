<div class="payment-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="payment" [class.active]="active">
    <div class="p-head">
        <div class="p-title"><span>{{words['ct_payment']?.['title']?.[lang.slug]}}</span></div>
        <button type="button" class="roundbtn rbtn-invert rbtn-close" (click)="onClose()"><span></span></button>
    </div>
    @if (user && tariff) {
        <!-- обзор платежа -->
        @if (step === "review") {
            <div class="p-content">
                <div class="p-tariff">
                    <div class="pt-namedesc">
                        <div class="pt-name">{{tariff.name[lang.slug]}}</div>
                        @if (tariff.description[lang.slug]) {<div class="pt-desc">{{tariff.description[lang.slug]}}</div>}
                    </div>                    
                    <div class="pt-price">{{tariff.price}} {{currency}}</div>
                </div>
            </div>    
            <div class="p-foot">
                <!-- есть карта -->
                @if (card_no) {
                    <div class="pf-method">
                        <div class="pfm-title">{{words['ct_payment']?.['method']?.[lang.slug]}}:</div>
                        <div class="pfm-card"><span class="pfm-brand">{{card_brand}}</span> **** **** **** {{card_no}}</div>
                    </div>
                    <div class="pf-btn"><button type="button" class="btn btn-invert btn-pay" (click)="onConfirm()"><span>{{words['ct_payment']?.['pay']?.[lang.slug]}}</span></button></div>        
                } 
                <!-- нет карты -->
                @else {
                    <div class="pf-btn"><button type="button" class="btn btn-invert" (click)="toCard()"><span>{{words['ct_payment']?.['continue']?.[lang.slug]}}</span></button></div>   
                }   
            </div>
        }
        <!-- привязка карты -->
        @if (step === "card") {
            <div class="p-content">
                <form class="p-stripe" (submit)="onSaveCard()">
                    <div class="ps-title">{{words['ct_payment']?.['add_method']?.[lang.slug]}}</div>
                    <div class="ps-form" id="ps-stripe-form"><div class="loading invert"><span></span></div></div>
                    @if (stripeError) {<div class="ps-error">{{stripeError}}</div>}
                    <button type="submit"></button>
                </form>
            </div>     
            <div class="p-foot">
                <div class="pf-btn"><button type="button" class="btn btn-invert btn-pay" [class.btn-busy]="saving === 'busy'" [class.btn-done]="saving === 'done'" (click)="onSaveCard()"><span>{{words['ct_payment']?.['save_pay']?.[lang.slug]}}</span></button></div>   
            </div>       
        }
    }
</div>
