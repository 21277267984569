<div class="menu-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="menu-panel" [class.active]="active">
    <div class="m-head">
        <div class="mh-logo"><span></span></div>
        <button type="button" class="roundbtn rbtn-invert rbtn-close" (click)="onClose()"><span></span></button>
    </div>
    <div class="m-content">
        <div class="m-menu">
            <div class="m-section">
                <a class="robots" (click)="onClose()" [class.active]="!url[2]" routerLink="/{{lang.slug}}">{{words["ct_menu"]?.["home"]?.[lang.slug]}}</a>
                <a class="subscriptions" (click)="onClose()" [class.active]="url[2] === 'subscriptions'" routerLink="/{{lang.slug}}/subscriptions">{{words["ct_menu"]?.["subscriptions"]?.[lang.slug]}}</a>
                <a class="method" (click)="onClose()" [class.active]="url[2] === 'method'" routerLink="/{{lang.slug}}/method">{{words["ct_menu"]?.["method"]?.[lang.slug]}}</a>
                <a class="transactions" (click)="onClose()" [class.active]="url[2] === 'transactions'" routerLink="/{{lang.slug}}/transactions">{{words["ct_menu"]?.["transactions"]?.[lang.slug]}}</a>
                <a class="profile" (click)="onClose()" [class.active]="url[2] === 'profile'" routerLink="/{{lang.slug}}/profile">{{words["ct_menu"]?.["profile"]?.[lang.slug]}}</a>
                <a class="about" (click)="onClose()" [class.active]="url[2] === 'about'" routerLink="/{{lang.slug}}/about">{{words["ct_menu"]?.["about"]?.[lang.slug]}}</a>
            </div>
            <div class="m-section">
                <a class="logout" (click)="onClose()" routerLink="/{{lang.slug}}/auth/logout">{{words["ct_menu"]?.["logout"]?.[lang.slug]}}</a>
            </div>            
        </div>
        @if (langs.length > 1) {<div class="m-langs"><menu-langs /></div>}
    </div>
</div>