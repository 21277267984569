import { Injectable } from '@angular/core';
import { IFiles } from 'src/app/model/entities/files';
import { CRepository } from './_repository';

@Injectable({providedIn: "root"})
export class CFileRepository extends CRepository {
    public async all(): Promise<IFiles> {        
        const res = await this.dataService.filesAll();
        if (res.statusCode !== 200) throw res.error;
        return res.data;        
    }
}
