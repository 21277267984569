import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { CAppService } from './common/services/app.service';
import { CLangRepository } from './common/services/repositories/lang.repository';
import { CSettingRepository } from './common/services/repositories/setting.repository';
import { CWordRepository } from './common/services/repositories/word.repository';
import { CFileRepository } from './common/services/repositories/file.repository';
import { ISettings } from './model/entities/settings';
import { Location } from '@angular/common';
import { ini } from './app.ini';
import { filter } from 'rxjs';
import { domGetElementById } from './common/functions/dom';
import { CHeaderComponent } from './common/components/structure/header/header.component';
import { CAuthService } from './common/services/auth.service';
import { mscPause } from './common/functions/misc';
import { CErrorPanelComponent } from './common/components/panels/error/error.panel.component';
import { CPaymentPanelComponent } from './common/components/panels/payment/payment.panel.component';
import { CMenuPanelComponent } from './common/components/panels/menu/menu.panel.component';
import { CAttachPanelComponent } from './common/components/panels/attach/attach.panel.component';
import { CBleNotificationPanelComponent } from './common/components/panels/ble-notification/ble-notification.panel.component';
import { CConfirmPanelComponent } from './common/components/panels/confirm/confirm.panel.component';
import { CRobotBackClient } from './common/services/socket/robot.back.client';
import { IRoomsSocket } from './model/dto/rooms.socket';

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
	styleUrls: [
        "common/styles/var.scss",
        "common/styles/layout.scss",
        "common/styles/general.scss",
        "common/styles/helpers.scss",
        "common/styles/buttons.scss",
		"common/styles/forms.scss",
    ],	
    imports: [
        RouterOutlet,
		CHeaderComponent,
		CMenuPanelComponent,
		CErrorPanelComponent,
		CPaymentPanelComponent,
		CAttachPanelComponent,
		CBleNotificationPanelComponent,
		CConfirmPanelComponent,
    ],
})
export class CAppComponent {
    private settingsReady: boolean = false;
	private langsReady: boolean = false;
	private wordsReady: boolean = false;
	private filesReady: boolean = false;

	constructor(
		private appService: CAppService,			
		private authService: CAuthService,
		private langRepository: CLangRepository,
		private settingRepository: CSettingRepository,	
		private wordRepository: CWordRepository,	
		private fileRepository: CFileRepository,
		private router: Router,
		private location: Location,
		private robotBackClient: CRobotBackClient,
	) {}

	get settings(): ISettings {return this.appService.settings;}
	get ready(): boolean {return this.settingsReady && this.settings["active"] === "1" && this.langsReady && this.wordsReady && this.filesReady;}
	get dir(): string {return this.appService.lang?.value?.dir || 'ltr';}
	get authenticated(): boolean {return this.authService.token.value !== null;}
	
    async ngOnInit(): Promise<void> {			
        await Promise.all([
			this.initSettings(),
			this.initLangs(),
			this.initWords(),
			this.initFiles(),	
		]);		
		this.initWin();	
		this.initSocket();						
    }

    private async initSettings(): Promise<void> {
		try {
			this.appService.settings = await this.settingRepository.all();	            
			this.settingsReady = true;	
		} catch (err) {
			this.appService.notifyError(err);
		}			
	}	

    private async initLangs(): Promise<void> {
		try {
            this.appService.langs = await this.langRepository.all();	
			this.appService.defaultLang = this.appService.langs.find(l => l.slug === ini.defaultLang);		
			const langableFragment = this.location.path().split("/")[1];
			const slug = langableFragment?.split("#")[0] || null;
			this.initLang(slug);	
			this.langsReady = true;			
			this.router.events
				.pipe(filter(event => event instanceof NavigationStart))
				.subscribe((event: NavigationStart) => {
					const langableFragment = event.url.split("/")[1];
					const slug = langableFragment?.split("#")[0] || null;
					this.initLang(slug);
				});            
		} catch (err) {
			this.appService.notifyError(err);
		}		
	}	

	private initLang(slug: string): void {
		if (!slug) {
			this.appService.setLang(this.appService.defaultLang);
			return;
		}

		const lang = this.appService.langs.find(l => l.slug === slug);
		
		if (!lang) {
            this.appService.setLang(this.appService.defaultLang);
			this.router.navigateByUrl(`/${ini.defaultLang}`); // здесь 404 не нужно, просто уходим на язык по умолчанию
			return;
		}

		this.appService.setLang(lang);	
	}

    private async initWords(): Promise<void> {
		try {
			this.appService.words = await this.wordRepository.all();				
			this.wordsReady = true;			
		} catch (err) {
			this.appService.notifyError(err);
		}		
	}

	private async initFiles(): Promise<void> {
		try {
			this.appService.files = await this.fileRepository.all();	
			this.filesReady = true;		
		} catch (err) {
			this.appService.notifyError(err);	
		}
	}	

	private async initWin(): Promise<void> {
        this.appService.win = await domGetElementById("win");
        this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => this.appService.win.scrollTop && setTimeout(() => this.appService.win.scrollTo({top: 0}), 1));	
		const splash = document.getElementById("splash");
		await mscPause(500);
		splash.classList.add("transparent");
    }

	private async initSocket(): Promise<void> {		
		this.robotBackClient.on<IRoomsSocket[]>("from:robot.back|to:control.front|room").subscribe(data => this.onRoomUpdated(data));
		this.authService.token.subscribe(t => t ? this.robotBackClient.connect() : this.robotBackClient.disconnect());
	}

	private onRoomUpdated(robotSockets: IRoomsSocket[]): void {		
		// актуальные сохраняем
		for (let rs of robotSockets) {
			this.appService.robotSockets[rs.id] = rs;
		}
		
		// неактуальные удаляем
		for (let id in this.appService.robotSockets) {
			if (!robotSockets.map(rs => rs.id.toString()).includes(id)) {
				delete this.appService.robotSockets[id];
			}
		}
	}

	// <a class="routerlink"> to router-like behavior
	@HostListener('document:click', ['$event'])
	public onClick(event: Event): void {
 		if (event.target instanceof HTMLAnchorElement) {
   			const element = event.target as HTMLAnchorElement;
   			if (element.classList.contains('routerlink')) {
     			event.preventDefault();
     			const route = element.getAttribute('href');     
       			route && this.router.navigateByUrl(route);
   			}
 		}
	}	
}
