import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable, tap } from "rxjs";
import { Router } from "@angular/router";
import { ini } from "src/app/app.ini";

export function authInterceptor(request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>>  {
    const router = inject(Router);
    return next(request).pipe(tap(event => {
        if (event instanceof HttpResponse && event.body["statusCode"] === 403) {
            router.navigateByUrl(`/${ini.defaultLang}/auth/logout`); // язык на этом этапе может быть не загружен, но это и не важно, это юзер-нарушитель
        }            
    }));   
}
