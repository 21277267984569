import { Injectable } from '@angular/core';
import { IWords } from 'src/app/model/entities/words';
import { CRepository } from './_repository';

@Injectable({providedIn: "root"})
export class CWordRepository extends CRepository {    
    public async all(): Promise<IWords> {        
        const res = await this.dataService.wordsAll();
        if (res.statusCode !== 200) throw res.error;
        return res.data;        
    }     
}
