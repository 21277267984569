import { Component, OnInit } from "@angular/core";
import { CActivatorableComponent } from "../../_activatorable.component";
import { CAppService } from "src/app/common/services/app.service";
import { CConfirmPanelService } from "./confirm.panel.service";
import { BehaviorSubject, filter } from "rxjs";
import { IKeyValue } from "src/app/model/keyvalue";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "panel-confirm",
    templateUrl: "confirm.panel.component.html",
    styleUrl: "confirm.panel.component.scss",
    standalone: true,
    imports: [FormsModule],
})
export class CConfirmPanelComponent extends CActivatorableComponent implements OnInit {
    public name: string = "";
    public errors: IKeyValue<string> = {};

    constructor(
        protected override appService: CAppService,
        protected panelService: CConfirmPanelService,
    ) 
    {
        super(appService);
    }

    get activator(): BehaviorSubject<boolean> {return this.panelService.active;}
    get requestedName(): string {return this.panelService.requestedName;}
    set confirmed(v: boolean) {this.panelService.confirmed = v;}

    //////////////////
    // lifecycle
    //////////////////

    ngOnInit(): void {
        // reset on activation            
        this.activator.pipe(filter(a => a)).subscribe(() => {
            this.name = "";
            this.errors = {};
        }); 
    }

    /////////////////
    // events
    /////////////////

    public onConfirm(): void {
        if (!this.validate()) return;
        this.confirmed = true;
        this.onClose();
    }

    ////////////////
    // utils
    ////////////////

    private validate(): boolean {
        let error = false;
        this.errors["name"] = null;

        if (this.name !== this.requestedName) {
            this.errors["name"] = "robot_name";
            error = true;
        }

        return !error;
    }
}
