import { ErrorHandler, Injectable } from "@angular/core";
import { CErrorRepository } from "./repositories/error.repository";

// глобальный обработчик неоткэтченных ошибок
@Injectable({providedIn: "root"})
export class CErrorsService implements ErrorHandler {
    constructor(private errorRepository: CErrorRepository) {}

    public handleError(error: any): void {
        const data = {
            message: error.message,
            stack: error.stack,
            userAgent: navigator.userAgent,
        };
        this.errorRepository.create(JSON.stringify(data)).catch(err => console.log(err));
        console.log(error);
    }
}
