import { Component } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CActivatorableComponent } from "../../_activatorable.component";
import { TErrorMode } from "src/app/model/error.mode";

@Component({
    selector: "panel-error",
    templateUrl: "error.panel.component.html",
    styleUrl: "error.panel.component.scss",
    standalone: true,
})
export class CErrorPanelComponent extends CActivatorableComponent {    
    get activator(): BehaviorSubject<boolean> {return this.appService.errorPanelActive;}
    get msg(): string {return this.appService.errorMsg;}
    get mode(): TErrorMode {return this.appService.errorMode;}

    public onReload(): void {
        document.location = document.location;
    }
}
