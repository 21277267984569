<div class="select-subscription">
    @for (s of subscriptions; track s.id) {
        <div class="ss-item" [class.active]="value === s.id" (click)="onSelect(s.id)">
            <div class="ssi-param">
                <div class="ssi-title">{{words["ct_subs"]?.["tariff"]?.[lang.slug]}}</div>
                <div class="ssi-value">
                    <span>{{s.tariff.name[lang.slug]}}</span>
                    @if (s.tariff.description[lang.slug]) {<br><span>{{s.tariff.description[lang.slug]}}</span>}                                
                </div>
            </div>                
            <div class="ssi-param">
                <div class="ssi-title">{{words["ct_subs"]?.["status"]?.[lang.slug]}}</div>
                <div class="ssi-value" [class.alert]="s.status === 'failed'">{{words["ct_subs"]?.["status_"+s.status]?.[lang.slug]}}</div>
            </div>   
        </div>
    }
</div>
