import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CComponent } from "src/app/common/components/_component";
import { ITariff } from "src/app/model/entities/tariff";

@Component({
    selector: "select-tariff",
    templateUrl: "select-tariff.component.html",
    styleUrl: "select-tariff.component.scss",
    standalone: true,
})
export class CSelectTariffComponent extends CComponent implements OnChanges {
    @Input() public tariffs: ITariff[] = [];
    @Input() public value: number;
    @Output() private valueChange: EventEmitter<number> = new EventEmitter();

    public currentValue: number = null;

    //////////////////
    // lifecycle
    //////////////////

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["value"] && this.currentValue !== this.value) {
            this.currentValue = this.value;
        }
    }

    //////////////////
    // events
    //////////////////

    public onSelect(value: number): void {
        this.valueChange.emit(value);
    }
}