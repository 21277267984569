import { Component, OnInit } from "@angular/core";
import { CActivatorableComponent } from "../../_activatorable.component";
import { CAppService } from "src/app/common/services/app.service";
import { CAttachPanelService } from "./attach.panel.service";
import { BehaviorSubject, filter } from "rxjs";
import { CSubscriptionRepository } from "src/app/common/services/repositories/subscription.repository";
import { ISubscription } from "src/app/model/entities/subscription";
import { mscPause } from "src/app/common/functions/misc";
import { ITariff } from "src/app/model/entities/tariff";
import { CAuthService } from "src/app/common/services/auth.service";
import { IUser } from "src/app/model/entities/user";
import { CTariffRepository } from "src/app/common/services/repositories/tariff.repository";
import { CSelectSubscriptionComponent } from "./components/select-subscription/select-subscription.component";
import { CSelectTariffComponent } from "./components/select-tariff/select-tariff.component";
import { TBusyState } from "src/app/model/busy.state";
import { IRobotAttach } from "src/app/model/entities/robot";
import { CRobotRepository } from "src/app/common/services/repositories/robot.repository";
import { Appearance, loadStripe, Stripe, StripeConstructorOptions, StripeElements } from "@stripe/stripe-js";
import { ini } from "src/app/app.ini";
import { domGetElementById } from "src/app/common/functions/dom";

// панель появляется при попытке прикрепить робота к аккаунту
// цель - повесить робота сразу на какую-то подписку (панель предлагает выбрать или создать подписку)
// возвращает id созданного робота
@Component({
    selector: "panel-attach",
    templateUrl: "attach.panel.component.html",
    styleUrl: "attach.panel.component.scss",
    standalone: true,
    imports: [
        CSelectSubscriptionComponent,
        CSelectTariffComponent,
    ],
})
export class CAttachPanelComponent extends CActivatorableComponent implements OnInit {
    public step: "subscriptions" | "new_subscription" | "card" = "subscriptions";
    public subscription_id: number = null; // это внутренний для окна, во внешнюю функцию сервис отдаст свой subscription_id
    public subscriptions: ISubscription[] = null;    
    public tariffs: ITariff[] = null;
    public tariff_id: number = null;
    public attaching: TBusyState = null;
    private stripe: Stripe = null;
    private stripeElements: StripeElements = null;
    public stripeError: string = null;

    constructor(
        protected override appService: CAppService,
        protected panelService: CAttachPanelService,
        protected authService: CAuthService,
        protected subscriptionRepository: CSubscriptionRepository,
        protected tariffRepository: CTariffRepository,
        protected robotRepository: CRobotRepository,
    ) 
    {
        super(appService);
    }

    get activator(): BehaviorSubject<boolean> {return this.panelService.active;}
    get robot_name(): string {return this.panelService.robot_name;}
    get user(): IUser {return this.authService.user.value;}
    get card_no(): string {return this.user.stripe_card_no;}
    get card_brand(): string {return this.user.stripe_card_brand;}
    get stripeKey(): string {return this.appService.settings["stripe-public-key"];}
    
    //////////////////
    // lifecycle
    //////////////////

    ngOnInit(): void {
        // reinit on activation            
        this.activator.pipe(filter(a => a)).subscribe(() => this.toSubscriptions());
    }

    private async initSubscriptions(): Promise<void> {
        try {
            this.subscriptions = null;
            this.subscription_id = null;
            await mscPause(300);
            const subscriptions = await this.subscriptionRepository.allWithoutRobot();

            if (!subscriptions.length) { // если нет неприкрепленных подписок, то уходим на создание новой подписки
                this.toNewSubscription();
                return;
            } 

            this.subscriptions = subscriptions;
            this.subscription_id = this.subscriptions[0].id;
        } catch (err) {
            this.appService.notifyError(err);
        }
    }

    private async initUser(): Promise<void> {
        try {
            const user = await this.authService.me(); // перезагружаем юзера, чтобы актуализировать параметр can_try
            this.authService.updateUser(user);
        } catch (err) {
            this.appService.notifyError(err);
        }
    }

    private async initTariffs(): Promise<void> {
        try {
            this.tariffs = null;
            this.tariff_id = null;
            await mscPause(300);
            await this.initUser();
            this.tariffs = this.user.can_try ? await this.tariffRepository.allMain() : await this.tariffRepository.allMainNonTrial();  
        } catch (err) {
            this.appService.notifyError(err);
        }
    }

    ///////////////
    // events
    ///////////////

    public toSubscriptions(): void {
        this.step = "subscriptions";
        this.initSubscriptions();
    }

    public toNewSubscription(): void {
        this.step = "new_subscription";
        this.initTariffs();
    }

    public async toCard(): Promise<void> {
        try {
            this.step = "card";
            const secret = await this.authService.createStripeIntent();
            this.stripe = await loadStripe(this.stripeKey, {locale: this.lang.slug as StripeConstructorOptions["locale"]});
            this.stripeElements = this.stripe.elements({clientSecret: secret, appearance: ini.stripeAppearance.invert as Appearance});             
            const element = await domGetElementById("as-stripe-form");
            this.stripeElements.create("payment", {layout: "tabs"}).mount(element);
        } catch (err) {
            this.appService.notifyError(err);
        }
    }

    public async onAttachWithSubscription(): Promise<void> {
        try {
            this.attaching = "busy";
            await mscPause(300);
            const dto: IRobotAttach = {name: this.robot_name, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, subscription_id: this.subscription_id};
            const robot = await this.robotRepository.attach(dto);
            this.attaching = "done";
            await mscPause(1000);
            this.attaching = null;
            this.panelService.robot_id = robot.id;
            this.onClose();
        } catch (err) {
            this.attaching = null;
            this.appService.notifyError(err);
        }        
    }

    public async onAttachWithTariff(): Promise<void> {
        try {
            this.attaching = "busy";
            await mscPause(300);
            const dto: IRobotAttach = {name: this.robot_name, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, tariff_id: this.tariff_id};
            const robot = await this.robotRepository.attach(dto);
            this.attaching = "done";
            await mscPause(1000);
            this.attaching = null;
            this.panelService.robot_id = robot.id;
            this.onClose();
        } catch (err) {
            this.attaching = null;
            this.appService.notifyError(err);
        }
    }

    public async onSaveCardAndAttachWithTariff(): Promise<void> {
        try {
            this.attaching = "busy";
            const res = await this.stripe.confirmSetup({elements: this.stripeElements, redirect: "if_required"}); // "if_required" - отключаем принудительный редирект

            // stripe подтвердил
            if (!res.error) {
                await this.authService.saveStripeCard(res.setupIntent.payment_method as string);
                this.onAttachWithTariff();
                return;
            }

            // stripe не подтвердил
            this.attaching = null;
            this.stripeError = res.error.type !== "validation_error" ? res.error.message : null; // validation_error показывает сама форма, их не надо дополнительно показывать  
        } catch (err) {
            this.attaching = null;
            this.appService.notifyError(err);
        }
    }
}