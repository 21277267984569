<div class="attach-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="attach" [class.active]="active">
    <div class="a-head">
        <div class="a-title"><span>{{words['ct_attach']?.['title']?.[lang.slug]}}</span></div>
        <button type="button" class="roundbtn rbtn-invert rbtn-close" (click)="onClose()"><span></span></button>
    </div>
    <!-- выбор подписки -->
    @if (step === "subscriptions") {
        <div class="a-content">
            @if (!subscriptions) {<div class="loading invert"><span></span></div>}
            @else {                
                <div class="a-subscriptions">
                    <div class="as-title">{{words['ct_attach']?.['select_subscription']?.[lang.slug]}}</div>
                    <div class="as-items"><select-subscription [subscriptions]="subscriptions" [(value)]="subscription_id" /></div>                                        
                </div>
            }
        </div>   
        <div class="a-foot">
            @if (subscriptions) {
                <div class="af-btn"><button type="button" class="btn btn-invert" [class.btn-disabled]="!subscription_id" [class.btn-busy]="attaching === 'busy'" [class.btn-done]="attaching === 'done'" (click)="onAttachWithSubscription()"><span>{{words['ct_attach']?.['confirm_subscription']?.[lang.slug]}}</span></button></div>
                <div class="af-or"><span>{{words['common']?.['or']?.[lang.slug]}}</span></div>
                <div class="af-btn"><button type="button" class="btn btn-invert" [class.btn-disabled]="attaching" (click)="toNewSubscription()"><span>{{words['ct_attach']?.['new_subscription']?.[lang.slug]}}</span></button></div>
            }            
        </div>     
    }
    <!-- новая подписка -->
    @if (step === "new_subscription") {
        <div class="a-content">
            @if (!tariffs) {<div class="loading invert"><span></span></div>}
            @else {
                @if (subscriptions?.length) {<div class="a-back" (click)="toSubscriptions()"><a>{{words['common']?.['back']?.[lang.slug]}}</a></div>}
                <div class="a-tariffs">
                    <div class="at-title">{{words['ct_attach']?.['select_tariff']?.[lang.slug]}}</div>
                    <div class="at-desc">{{words['ct_attach']?.['select_tariff_desc']?.[lang.slug]}}</div>
                    <div class="at-items"><select-tariff [tariffs]="tariffs" [(value)]="tariff_id" /></div>                                        
                </div>
            }
        </div>
        <div class="a-foot">
            <!-- есть карта -->
            @if (card_no) {
                <div class="af-method">
                    <div class="afm-title">{{words['ct_attach']?.['method']?.[lang.slug]}}:</div>
                    <div class="afm-card"><span class="pfm-brand">{{card_brand}}</span> **** **** **** {{card_no}}</div>
                </div>
                <div class="af-btn"><button type="button" class="btn btn-invert btn-pay" [class.btn-busy]="attaching === 'busy'" [class.btn-done]="attaching === 'done'" [class.btn-disabled]="!tariff_id" (click)="onAttachWithTariff()"><span>{{words['ct_attach']?.['pay']?.[lang.slug]}}</span></button></div>        
            } 
            <!-- нет карты -->
            @else {
                <div class="af-btn"><button type="button" class="btn btn-invert" [class.btn-disabled]="!tariff_id" (click)="toCard()"><span>{{words['ct_attach']?.['continue']?.[lang.slug]}}</span></button></div>   
            }   
        </div>
    }
    <!-- привязка карты -->
    @if (step === "card") {
        <div class="a-content">
            <div class="a-back"><a (click)="toNewSubscription()">{{words['common']?.['back']?.[lang.slug]}}</a></div>
            <form class="a-stripe" (submit)="onSaveCardAndAttachWithTariff()">
                <div class="as-title">{{words['ct_attach']?.['add_method']?.[lang.slug]}}</div>
                <div class="as-form" id="as-stripe-form"><div class="loading invert"><span></span></div></div>
                @if (stripeError) {<div class="as-error">{{stripeError}}</div>}
                <button type="submit"></button>
            </form>
        </div>     
        <div class="a-foot">
            <div class="af-btn"><button type="button" class="btn btn-invert btn-pay" [class.btn-busy]="attaching === 'busy'" [class.btn-done]="attaching === 'done'" (click)="onSaveCardAndAttachWithTariff()"><span>{{words['ct_attach']?.['pay']?.[lang.slug]}}</span></button></div>   
        </div>       
    }
</div>
