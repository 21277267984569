import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CAuthService } from '../auth.service';
import { CAppService } from '../app.service';
import { filter, firstValueFrom } from 'rxjs';

@Injectable({providedIn: "root"})
export class CAuthGuard {    
    constructor (
        private authService: CAuthService,
        private appService: CAppService,
        private router: Router,
    ) {}

    public async canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {        
        if (this.authService.token.value) {
            return true;
        } 
            
        const lang = await firstValueFrom(this.appService.lang.pipe(filter(l => l !== null))); // ждем загрузку языка        
        this.router.navigateByUrl (`/${lang.slug}/auth/enter`);
        return false;               
    }
}
