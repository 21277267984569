<div class="bn-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="bn" [class.active]="active">
    <div class="bn-head">
        <div class="bn-title"><span>{{words['ct_ble']?.['title']?.[lang.slug]}}</span></div>
        <button type="button" class="roundbtn rbtn-invert rbtn-close" (click)="onClose()"><span></span></button>
    </div>
    <div class="bn-content">{{words['ct_ble']?.["mode_"+mode]?.[lang.slug]}}</div>
    <div class="bn-foot">
        <button type="button" class="btn btn-invert" (click)="onClose()"><span>{{words['ct_ble']?.['ok']?.[lang.slug]}}</span></button>
    </div>
</div>
