import { Component } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { CAppService } from "src/app/common/services/app.service";
import { ILang } from "src/app/model/entities/lang";
import { IKeyValue } from "src/app/model/keyvalue";
import { CComponent } from "../../_component";

@Component({
    selector: "menu-langs",
    templateUrl: "langs.menu.component.html",
    standalone: true,
    imports: [RouterLink],
})
export class CLangsMenuComponent extends CComponent {
    constructor(
        protected override appService: CAppService,
        protected router: Router,
    ) 
    {
        super(appService);
    }

    get defaultLang(): ILang {return this.appService.defaultLang;}
    get langs(): ILang[] {return this.appService.langs;}

    public getLangLink(lang: ILang, mode: "url" | "fragment" | "queryParams"): string | IKeyValue<string> {
        if (mode === "fragment") return this.getLangLinkFragment();
        if (mode === "queryParams") return this.getLangLinkQueryParams();
        return this.getLangLinkUrl(lang);
    }

    private getLangLinkUrl(lang: ILang): string {
        let delimiter = this.router.url.includes("#") ? "#" : (this.router.url.includes("?") ? "?" : "");            
        let preurl;
        
        if (delimiter) {
            const urlParts = this.router.url.split(delimiter);
            preurl = urlParts[0].split("/");                
        } else {
            preurl = this.router.url.split("/");
        }
        
        preurl.splice(0, 2);      
        return `/${lang.slug}/${preurl.join("/")}`; // здесь проще, чем на основном сайте, редиректа с главного языка нет
    }

    private getLangLinkFragment(): string {
        const urlParts = this.router.url.split("#");
        const fragment = urlParts[1] ? decodeURI(urlParts[1]) : null;
        return fragment;
    }

    private getLangLinkQueryParams(): IKeyValue<string> {
        const queryParams = {}; 
        const urlParts = this.router.url.split("?");        

        if (urlParts[1]) {
            const queryParamsParts = urlParts[1].split("&");

            for (let qpp of queryParamsParts) {
                const qppParts = qpp.split("=");
                queryParams[qppParts[0]] = qppParts[1];
            }                
        }

        return queryParams;
    }
}