import { Routes } from '@angular/router';
import { ini } from './app.ini';
import { CAuthGuard } from './common/services/guards/auth.guard';

export const routes: Routes = [
    {path: "", pathMatch: "full", redirectTo: `/${ini.defaultLang}`}, // т.к. здесь индексация не важна, проще сделать редирект с корня на дефолтный язык, чем склеивать два роута в один компонент через strategy
    {path: ":lang", loadComponent: () => import("./pages/home/home.page").then(mod => mod.CHomePage), canActivate: [CAuthGuard]}, 
    {path: ":lang/profile", loadComponent: () => import("./pages/profile/profile.page").then(mod => mod.CProfilePage), canActivate: [CAuthGuard]}, 
    {path: ":lang/control/:id", loadComponent: () => import("./pages/control/control.page").then(mod => mod.CControlPage), canActivate: [CAuthGuard]}, 
    {path: ":lang/subscriptions", loadComponent: () => import("./pages/subscriptions/index/index.subscriptions.page").then(mod => mod.CIndexSubscriptionsPage), canActivate: [CAuthGuard]}, 
    {path: ":lang/subscriptions/create", loadComponent: () => import("./pages/subscriptions/create/create.subscriptions.page").then(mod => mod.CCreateSubscriptionsPage), canActivate: [CAuthGuard]}, 
    {path: ":lang/subscriptions/:id", loadComponent: () => import("./pages/subscriptions/one/one.subscriptions.page").then(mod => mod.COneSubscriptionsPage), canActivate: [CAuthGuard]}, 
    {path: ":lang/method", loadComponent: () => import("./pages/method/index/index.method.page").then(mod => mod.CIndexMethodPage), canActivate: [CAuthGuard]}, 
    {path: ":lang/method/edit", loadComponent: () => import("./pages/method/edit/edit.method.page").then(mod => mod.CEditMethodPage), canActivate: [CAuthGuard]}, 
    {path: ":lang/transactions", loadComponent: () => import("./pages/transactions/transactions.page").then(mod => mod.CTransactionsPage), canActivate: [CAuthGuard]}, 
    {path: ":lang/about", loadComponent: () => import("./pages/about/about.page").then(mod => mod.CAboutPage), canActivate: [CAuthGuard]}, 
    {path: ":lang/auth/enter", loadComponent: () => import("./pages/auth/enter/enter.page").then(mod => mod.CEnterPage)},
    {path: ":lang/auth/logout", loadComponent: () => import("./pages/auth/logout/logout.page").then(mod => mod.CLogoutPage)},
    {path: ":lang/auth/oauth/:token", loadComponent: () => import("./pages/auth/oauth/oauth.page").then(mod => mod.COAuthPage)},
    {path: "**", redirectTo: `/${ini.defaultLang}`},
];
