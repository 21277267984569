<div id="win" [dir]="dir">    
    <panel-error />
    @if (ready) {        
        @if (authenticated) {<the-header />}
        <main><router-outlet /></main>        
        <panel-menu />
        <panel-payment />
        <panel-attach />
        <panel-ble-notification />
        <panel-confirm />
    }            
</div>
