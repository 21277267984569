import { Directive } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CComponent } from "./_component";

@Directive()
export abstract class CActivatorableComponent extends CComponent {
    public abstract activator: BehaviorSubject<boolean>;

    get active(): boolean {return this.activator.value;}

    /////////////////
    // events
    /////////////////
    
    public onClose(): void {
        this.activator.next(false);
    }    
}
