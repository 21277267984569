import { Component } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CAppService } from "src/app/common/services/app.service";
import { CConnectService } from "src/app/common/services/ble/connect.service";
import { CActivatorableComponent } from "../../_activatorable.component";

export type TBNPanelMode = "start" | "error";

@Component({
    selector: "panel-ble-notification",
    templateUrl: "ble-notification.panel.component.html",
    styleUrl: "ble-notification.panel.component.scss",
    standalone: true,
})
export class CBleNotificationPanelComponent extends CActivatorableComponent {
    constructor(
        protected override appService: CAppService,
        protected connectService: CConnectService,
    ) 
    {
        super(appService);
    }

    get activator(): BehaviorSubject<boolean> {return this.connectService.wvNotificationPanelActive;}
    get mode(): TBNPanelMode {return this.connectService.wvNotificationPanelMode;}
}
