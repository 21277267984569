import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CComponent } from "src/app/common/components/_component";
import { ISubscription } from "src/app/model/entities/subscription";

@Component({
    selector: "select-subscription",
    templateUrl: "select-subscription.component.html",
    styleUrl: "select-subscription.component.scss",
    standalone: true,
})
export class CSelectSubscriptionComponent extends CComponent implements OnChanges {
    @Input() public subscriptions: ISubscription[] = [];
    @Input() public value: number;
    @Output() private valueChange: EventEmitter<number> = new EventEmitter();

    public currentValue: number = null;

    //////////////////
    // lifecycle
    //////////////////

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["value"] && this.currentValue !== this.value) {
            this.currentValue = this.value;
        }
    }

    //////////////////
    // events
    //////////////////

    public onSelect(value: number): void {
        this.valueChange.emit(value);
    }
}