import { Component, ViewEncapsulation } from "@angular/core";
import { RouterLink } from "@angular/router";
import { CActivatorableComponent } from "../../_activatorable.component";
import { BehaviorSubject } from "rxjs";
import { CLangsMenuComponent } from "../../menus/langs/langs.menu.component";
import { ILang } from "src/app/model/entities/lang";

@Component({
    selector: "panel-menu",
    templateUrl: "menu.panel.component.html",
    styleUrl: "menu.panel.component.scss",
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [
        RouterLink,
        CLangsMenuComponent,
    ],
})
export class CMenuPanelComponent extends CActivatorableComponent {
    get activator(): BehaviorSubject<boolean> {return this.appService.menuActive;}
    get url(): string[] {return this.appService.url;}
    get langs(): ILang[] {return this.appService.langs;}
}
