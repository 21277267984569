import { inject } from '@angular/core';
import { HttpRequest, HttpEvent, HttpHeaders, HttpHandlerFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CDataService } from '../services/data.service';

export function headersInterceptor(request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
    const dataService = inject(CDataService);
    const headersContent = {"tzoffset": new Date().getTimezoneOffset().toString()}; // сдвиг от UTC в минутах
        
    if (dataService.token.value) {
        headersContent["token"] = dataService.token.value;
    }
    
    const headers = new HttpHeaders(headersContent);        
    const headersRequest = request.clone({headers});
    return next(headersRequest);
}
