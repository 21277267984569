<header>
    <div class="h-left">
        @if (backRoute) {<a class="roundbtn rbtn-back rbtn-invert" [routerLink]="backRoute"><span></span></a>}
        @else {<div class="h-logo" (click)="onLogoClick()"></div>}
    </div>
    <div class="h-right">
        <!--@if (isWebview) {<button type="button" class="roundbtn rbtn-invert rbtn-reload" (click)="onReload()"><span></span></button>}-->
        <button type="button" class="roundbtn rbtn-invert rbtn-menu" (click)="onMenu()"><span></span></button>
    </div>
</header>
