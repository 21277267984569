<div class="confirm-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="confirm" [class.active]="active">
    <div class="c-head">
        <div class="c-title"><span>{{words['ct_common']?.['confirm_title']?.[lang.slug]}}</span></div>
        <button type="button" class="roundbtn rbtn-invert rbtn-close" (click)="onClose()"><span></span></button>
    </div>
    <div class="c-content">
        <form class="gf" (submit)="onConfirm()">
            <div class="gf-row">
                <div class="gf-title">{{words["ct_common"]?.["confirm_name"]?.[lang.slug]}} ("{{requestedName}}")</div>
                <div class="gf-input"><input type="text" name="name" [(ngModel)]="name" [class.error]="errors['name']"></div>
                @if (errors['name']) {<div class="gf-error">{{words['errors']?.[errors['name']]?.[lang.slug]}}</div>}
            </div>
            <button type="submit"></button>
        </form>
    </div>
    <div class="c-foot">
       <div class="cf-btn"><button type="button" class="btn btn-invert" (click)="onConfirm()"><span>{{words['ct_common']?.['confirm_btn']?.[lang.slug]}}</span></button></div>   
    </div>
</div>
